// Mmenu custom build

// Core
import Mmenu from 'mmenu-js/dist/core/oncanvas/mmenu.oncanvas';

// Core add-ons
import offcanvas from 'mmenu-js/dist/core/offcanvas/mmenu.offcanvas';
import scrollBugFix from 'mmenu-js/dist/core/scrollbugfix/mmenu.scrollbugfix';

Mmenu.addons = {
	offcanvas,
	scrollBugFix
};

window.Mmenu = Mmenu;
